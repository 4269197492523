<template>
  <div class="product">

    <div class="product-details">

      <div class="space-y-6">
      
        <h2 class="product-title">{{title}}</h2>

        <div class="product-description">
          <slot name="description"></slot>
        </div>

        <a
          class="btn text primary inline"
          target="_blank"
          :href="'https://twitter.com/messages/compose?recipient_id=' + authorTwitterId + '&text=' + defaultDMText">
          <div>
            <inline-svg src="/img/icons/external.svg" />
            <span>{{ callToAction }}</span>
          </div>
        </a>

      </div>
    
    </div>

    <div class="product-image">
      <img :src="`img/school/${productPicture}`" alt="" class="object-fit-contain">
    </div>

    <div class="product-author">

      <a target="_blank" :href="authorTwitterProfile" class="product-author-image">
        <img alt="Image placeholder" :src="`img/school/${authorPicture}`">
      </a>

      <div class="product-author-bio">
        <h4><a target="_blank" :href="authorTwitterProfile">{{authorName}}</a></h4>
        <p>{{authorBio}}</p>
      </div>

    </div>

  </div>
</template>

<script>
  export default {
    props: {
      authorBio: String,
      authorName: String,
      authorPicture: String,
      authorTwitterProfile: String,
      authorTwitterId: String,
      callToAction: String,
      defaultDMText: String,
      productLink: String,
      productPicture: String,
      title: String,
    },
  };
</script>
