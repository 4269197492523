<template>
  <div>

    <h1 class="page-header">Do you want to grow faster?</h1>

    <div class="text-md">
      <p class="mb-2">
        Often the best way to grow is working with someone who walked in shoes
        and already figured it out.
      </p>
      <p>
        The people on this list are not only our business partners, they're our friends too.
        We can vouch for their results.
      </p>
    </div>

    <div class="container-fluid space-y-6 mt-6">
      <div v-for="product in products" v-bind:key="product.title">
        <marketplace-card
          :title="product.title"
          :authorName="product.authorName"
          :authorPicture="product.authorPicture"
          :authorTwitterId="product.authorTwitterId"
          :authorTwitterProfile="product.authorTwitterProfile"
          :callToAction="product.callToAction"
          :defaultDMText="product.defaultDMText"
          :productLink="product.productLink"
          :productPicture="product.productPicture"
          :authorBio="product.authorBio">
          <template v-slot:description>
            <p class="text-md" v-html="product.description"></p>
          </template>
        </marketplace-card>
      </div>
    </div>
  </div>
</template>

<script>
  import lodash from 'lodash';
  import MarketplaceCard from '@/views/MarketplaceCard';

  export default {
    components: {
      MarketplaceCard,
    },
    data() {
      return {
        products: lodash.shuffle([
          {
            title: 'Jeff Putnam',
            authorName: 'Jeff Putnam',
            authorPicture: 'jeffputnam.jpg',
            authorTwitterProfile: 'https://twitter.com/Rugged_Legacy',
            authorTwitterId: '1084494018266976256',
            callToAction: 'DM Jeff to learn more',
            defaultDMText: 'Hi Jeff. I am coming from Hypefury and would like to learn more about your ghostwriting service.',
            productLink: 'https://twitter.com/Rugged_Legacy',
            productPicture: null,
            authorBio: 'Two time Author and professional freelance writer specializing in online content creation. I turn your words into income.',
            description: `
<p>Coaches, Founders, Personal Brands</p>
<p>When building an audience, it all comes down to the content you put out.</p>
<p>The hard part is creating it.</p>
<p>You can do what everyone else does and buy Twitter guide after Twitter guide,</p>
<p>Or you can have me create the content and build the following for you.</p>
<p>I've built a following of almost 40,000 in less than 40 months and will write tweets that generate both growth and engagement.</p>
            `,
          },
        ]),
      };
    },
    mounted() {
      this.$eventStore.visitMarketplacePage();
    },
  };
</script>
